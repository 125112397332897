<!-- The Pre-Op Data and Calculations section of the Patient Detail and New Patient pages -->

<template>
    <div class="preop-data-calc-card py-2" :class="{'card-disabled': disabled}">
        <b-row class="mb-4">
            <b-col class="d-flex justify-content-between">
                <h5 class="heavy" :class="disabled ? 'card-disabled-label' : 'text-primary'">
                    {{ t('preopDataAndCalc_title') }}
                </h5>
                <b-button
                    variant="primary"
                    @click="goToPreOpData()"
                    :disabled="addPreOpDataButtonDisabled"
                    v-if="
                        checkPermissions({
                            [PERMISSIONS.PATIENT_PREOP_DATA]: PERMISSIONS_VALUES.READ_WRITE,
                        }) && patientHasSurgeon
                    "
                >
                    <b-icon-plus class="mr-1" /> {{ t('addPreOpData') }}
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="preop-datasets" :style="scrollYStyle">
                <div
                    class="preop-dataset"
                    :class="{'pb-128px': get(preOpDataSets, 'length', 0) == 1}"
                    v-for="preOpDataSet in preOpDataSets"
                    :key="preOpDataSet.preOpDataSetId"
                >
                    <div class="collapse-header p-3">
                        <div class="d-flex">
                            <div class="text-nowrap text-center mr-2">
                                <div v-if="preOpDataSet.dataSourceId !== DATA_SOURCES.OCOS">
                                    <b-link
                                        href="#"
                                        class="heavy"
                                        :class="{'disabled-link': addPreOpDataButtonDisabled}"
                                        :disabled="addPreOpDataButtonDisabled"
                                        @click="goToPreOpData(preOpDataSet)"
                                    >
                                        {{ preOpDataSet.updated | date }}
                                    </b-link>
                                </div>
                                <div v-else>
                                    {{ preOpDataSet.updated | date }}
                                </div>
                                <div class="text-gray-dark">
                                    {{ preOpDataSet.updated | date({format: 'HH:mm:ss'}) }}
                                </div>
                            </div>
                            <div class="text-black ml-2">
                                {{ getUpdatedByInfo(preOpDataSet) }}
                            </div>
                            <div class="ml-auto pl-2 d-flex">
                                <div class="text-black">
                                    {{ preOpDataSet.surgeonName }}
                                </div>
                                <div class="text-small text-gray-dark ml-2">
                                    ({{ preOpDataSet.contactPartyNumber }})
                                </div>
                                <b-icon
                                    class="when-open ml-2"
                                    icon="chevron-up"
                                    variant="gray-darker"
                                    font-scale="1"
                                    v-b-toggle="
                                        'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId
                                    "
                                />
                                <b-icon
                                    class="when-closed ml-2"
                                    icon="chevron-down"
                                    variant="gray-darker"
                                    font-scale="1"
                                    v-b-toggle="
                                        'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <b-collapse
                        visible
                        :id="'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId"
                    >
                        <div class="ml-4 mr-3 mt-2 preop-dataset-eye-group d-flex">
                            <div
                                class="mr-2"
                                :class="{
                                    'mt-4': Object.keys(preOpDataSet.preOpData).length > 1,
                                }"
                            >
                                <b-icon-clipboard-plus
                                    v-b-tooltip.hover
                                    :id="`copy-${preOpDataSet.preOpDataSetId}`"
                                    class="mr-1 pointer"
                                    font-scale="1.5"
                                    variant="dark-gray"
                                    @click="copyPreOpData(preOpDataSet)"
                                    v-if="
                                        !addPreOpDataButtonDisabled &&
                                        currentUser.accessPermissions.PatientPreOpData ==
                                            'ReadWrite' &&
                                        patientHasSurgeon
                                    "
                                />
                                <b-tooltip :target="`copy-${preOpDataSet.preOpDataSetId}`">
                                    {{ t('copyPreOpDataNewSet') }}
                                </b-tooltip>
                            </div>
                            <ul>
                                <template v-for="opEye in ['OD', 'OS']">
                                    <li
                                        v-if="preOpDataSet.preOpData[opEye]"
                                        :key="opEye"
                                        class="preop-dataset-eye-group-item pb-3"
                                    >
                                        <div
                                            class="preop-dataset-eye-group-item-title"
                                            :class="
                                                getPreOpDataEyeClass(preOpDataSet.preOpData, opEye)
                                            "
                                        >
                                            <b-button
                                                variant="no-outline"
                                                class="d-flex p-0"
                                                :disabled="
                                                    preOpDataSet.preOpData[opEye].dataSourceId ===
                                                        DATA_SOURCES.OCOS ||
                                                    addPreOpDataButtonDisabled
                                                "
                                                @click="goToPreOpData(preOpDataSet, opEye)"
                                            >
                                                <b-img
                                                    :src="getEyeIcon(opEye)"
                                                    :class="
                                                        getEyeIconClass(
                                                            preOpDataSet.preOpData[opEye].incomplete
                                                        )
                                                    "
                                                ></b-img>
                                                <div class="preop-dataset-eye-group-item-title">
                                                    <div
                                                        v-if="
                                                            preOpDataSet.preOpData[opEye].incomplete
                                                        "
                                                        class="ml-1 preop-dataset-eye-group-item-title-incomplete"
                                                    >
                                                        <em>
                                                            {{ opEye }}
                                                            {{ t('preopDataAndCalc_Incomplete') }}
                                                        </em>
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="ml-1"
                                                        :class="
                                                            getCheckClass(
                                                                kPreOpDataStatus.SAVED,
                                                                preOpDataSet.preOpData[opEye].status
                                                            )
                                                        "
                                                    >
                                                        {{ opEye }}
                                                        {{ t('preopDataAndCalc_PreOpSaved') }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </div>
                                        <div
                                            v-if="
                                                getActiveRefractionInfo(
                                                    preOpDataSet.preOpData[opEye]
                                                )
                                            "
                                            class="d-flex preop-dataset-eye-group-item-detail"
                                            :class="
                                                getCheckClass(
                                                    kPreOpDataStatus.CALCULATED,
                                                    preOpDataSet.preOpData[opEye].status
                                                )
                                            "
                                        >
                                            <div class="mr-1 d-flex">
                                                <div
                                                    v-if="
                                                        preOpDataSet.preOpData[opEye].status ===
                                                        kPreOpDataStatus.SAVED
                                                    "
                                                >
                                                    {{ t('preopDataAndCalc_Refraction') }}
                                                </div>
                                                <div v-else>
                                                    {{ t('preopDataAndCalc_Calculation') }}
                                                </div>
                                                <div>
                                                    &nbsp;({{
                                                        getLocalizedRefactionInfo(
                                                            preOpDataSet.preOpData[opEye]
                                                        )
                                                    }})
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    preOpDataSet.preOpData[opEye].status ===
                                                        kPreOpDataStatus.LOCKED ||
                                                    preOpDataSet.preOpData[opEye].dataSourceId ===
                                                        DATA_SOURCES.OCOS
                                                "
                                            >
                                                <b-img
                                                    :src="padLockIcon"
                                                    height="18"
                                                    width="18"
                                                    class="mr-1 pb-1"
                                                ></b-img>
                                            </div>
                                            <div
                                                v-if="
                                                    preOpDataSet.preOpData[opEye].dataSourceId ===
                                                    DATA_SOURCES.OCOS
                                                "
                                                class="mr-1 text-small text-red"
                                            >
                                                OCOS
                                            </div>
                                            <div
                                                v-if="
                                                    preOpDataSet.preOpData[opEye].calculatorVersion
                                                "
                                                class="mr-1 text-small text-gray-dark"
                                            >
                                                v{{
                                                    preOpDataSet.preOpData[opEye].calculatorVersion
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                preOpDataSet.preOpData[opEye].targetLensDescription
                                            "
                                            class="mr-1 preop-dataset-eye-group-item-detail"
                                        >
                                            <div
                                                :class="
                                                    getCheckClass(
                                                        kPreOpDataStatus.LOCKED,
                                                        preOpDataSet.preOpData[opEye].status
                                                    ) ||
                                                    getCheckClass(
                                                        kPreOpDataStatus.TARGET_LENS_PICKED,
                                                        preOpDataSet.preOpData[opEye].status
                                                    )
                                                "
                                            >
                                                {{
                                                    decimalSeparatorFormatter(
                                                        preOpDataSet.preOpData[opEye]
                                                            .targetLensDescription,
                                                        decimalSeparator
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </b-collapse>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import od_icon from '@/assets/od_icon.svg';
import os_icon from '@/assets/os_icon.svg';
import padlock from '@/assets/padlock.svg';
import circleCheck from '@/assets/circleCheck.svg';
import {Status, EyeSides} from '@/store/modules/preopdata';
import {mapGetters} from 'vuex';
import find from 'lodash/find';
import pick from 'lodash/pick';
import get from 'lodash/get';
import {decimalSeparatorFormatter} from '@/utilities/formatters';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {DATA_SOURCES} from '@/constants/preopdata';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'PreOpDataAndCalc',
    props: {
        patient: {
            type: Object,
            default: () => {},
        },
        preOpDataSets: {
            default: function () {
                return [];
            },
        },
        scrollYWhenRecords: {
            type: Number,
            default: 6,
        },
        disabled: {
            default: false,
        },
    },

    data() {
        return {
            DATA_SOURCES,
            padLockIcon: padlock,
            circleCheckIcon: circleCheck,
            kPreOpDataStatus: Status,
            kPreOpEyes: EyeSides,
        };
    },

    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
        scrollYStyle() {
            const heightPerRow = 120;
            return {maxHeight: this.scrollYWhenRecords * heightPerRow + 'px'};
        },
        patientHasSurgeon: function () {
            return (
                this.patient?.doctorId != undefined &&
                this.patient?.doctorId !== 0 &&
                this.patient?.doctorId !== '0'
            );
        },
        addPreOpDataButtonDisabled: function () {
            return this.disabled || !this.patient?.doctorActive;
        },
    },

    methods: {
        get,
        decimalSeparatorFormatter,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        /**
         * Get information about the user that updated the dataset
         *
         * @param {Object} preOpData
         */
        getUpdatedByInfo(preOpData) {
            const updatedBy = preOpData.updatedBy;
            const updatedByOcosId = preOpData.updatedByOcosId;
            let updatedByInfo = '';

            if (updatedBy || updatedByOcosId) {
                if (updatedBy) {
                    updatedByInfo += updatedBy;

                    if (updatedByOcosId) {
                        updatedByInfo += ` (${updatedByOcosId})`;
                    }
                } else if (updatedByOcosId) {
                    updatedByInfo += ` ${updatedByOcosId}`;
                }
            } else {
                updatedByInfo = this.t('preopDataAndCalc_NA');
            }

            return updatedByInfo;
        },
        /**
         * Determine which eye icon to display
         *
         * @param {String} opEye - the eye to get the icon for
         */
        getEyeIcon(opEye) {
            if (opEye === this.kPreOpEyes.OD) {
                return od_icon;
            } else if (opEye === this.kPreOpEyes.OS) {
                return os_icon;
            }
        },
        /**
         * Determine the styling to apply to the eye icon
         *
         * @param {Boolean} incomplete - determines if the eye dataset is
         *  incomplete
         */
        getEyeIconClass(incomplete) {
            return incomplete ? 'preop-dataset-eye-group-item-title-icon-incomplete' : '';
        },
        /**
         * Get the active refraction info
         *
         * @param {Object} preOpDataForOpEye - the pre-op dataset for an eye
         */
        getActiveRefractionInfo(preOpDataForOpEye) {
            return find(preOpDataForOpEye?.refractionInfo, 'active');
        },
        /**
         * Get the localized active refraction info
         *
         * @param {object} preOpDataForOpEye - the pre-op dataset for an eye
         */
        getLocalizedRefactionInfo(preOpDataForOpEye) {
            let refStr = find(preOpDataForOpEye?.refractionInfo, 'active').refractionName;

            if (refStr.toLowerCase() === 'cyclopegic') {
                return this.t('preop_Cycloplegic');
            } else if (refStr.toLowerCase() === 'cl over-refraction') {
                return this.t('preop_CLOVERREF');
            }
            return this.t('preop_Manifest');
        },

        /**
         * Get the class to apply to the eye group
         *
         * @param {Object} preOpData - the pre-op dataset
         */
        getPreOpDataEyeClass(preOpData) {
            let preopClass = '';
            if (preOpData[this.kPreOpEyes.OD] && preOpData[this.kPreOpEyes.OS]) {
                preopClass = `preop-dataset-eye-group-link`;
            } else if (preOpData[this.kPreOpEyes.OD] || preOpData[this.kPreOpEyes.OS]) {
                preopClass = 'preop-dataset-eye-group-bullet';
            }
            return preopClass;
        },
        /**
         * Determine whether to display the check mark or not
         *
         * @param {String} preOpDataSection - the UI section of the dataset
         * @param {String} currentState - the state of the dataset eye group
         */
        getCheckClass(preOpDataSection, currentState) {
            let checkClass = '';
            if (preOpDataSection === currentState) {
                checkClass = 'show-check';
            }
            return checkClass;
        },
        /**
         * Navigate to the patient's pre-op data page
         *
         * @param {String} preOpDataSetId - the pre-op dataset id
         * @param {String} hash - the hash to include in the URL
         */
        goToPreOpData(params, side = null) {
            const hasPermissions = this.checkPermissions({
                [PERMISSIONS.PATIENT_PREOP_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            });

            const query = {};
            if (side) {
                query.side = side;
            }

            if (hasPermissions) {
                this.$router.push({
                    name: 'PreOpData',
                    params: {
                        ...pick(params, ['preOpDataSetId', 'isCopying']),
                        patientId: this.patient.patientId,
                    },
                    query,
                });
            }
        },
        async copyPreOpData(preOpDataSet) {
            await this.blockingRequest('preopdata/copyPreopdata', {
                patientId: this.patient.patientId,
                preOpDataSetId: preOpDataSet.preOpDataSetId,
                decimalSeparator: this.decimalSeparator,
            });
            this.goToPreOpData({isCopying: true});
        },
    },
};
</script>
<style scoped lang="scss">
@import '../../../assets/css/_variables';

.preop-data-calc-card {
    .no-wrap-words {
        white-space: nowrap;
    }

    .heavy {
        font-weight: 700;
    }

    .disabled-link {
        pointer-events: none;
        cursor: default;
        color: $gray-dark !important;
    }

    .preop-datasets {
        overflow-y: auto;
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        .pb-128px {
            padding-bottom: 128px;
        }

        .text-small {
            line-height: 1.14rem;
            font-weight: 400;
        }

        .preop-dataset:last-of-type {
            border-bottom: 1px solid $gray-lighter;
        }

        .collapse-header {
            font-size: 0.857rem;
            border-top: 1px solid $gray-lighter;

            .b-icon {
                &.collapsed.when-open,
                &.not-collapsed.when-closed {
                    display: none;
                }

                &:focus {
                    outline: none;
                }
            }
        }
        .preop-dataset-eye-group {
            ul {
                list-style: none;
                margin-bottom: 0px;
                padding-left: 5px;

                li {
                    position: relative;
                    padding-left: 1em;

                    > div.preop-dataset-eye-group-bullet::before {
                        position: absolute;
                        top: 7px;
                        left: -7px;
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: $gray-light;
                        border-radius: 100px;
                    }

                    > div.preop-dataset-eye-group-link {
                        &::before {
                            position: absolute;
                            top: 9px;
                            bottom: -9px;
                            left: -5px;
                            display: block;
                            border-left: 3px solid $gray-light;
                            content: '';
                        }

                        &::after {
                            position: absolute;
                            top: 6px;
                            left: -5px;
                            display: block;
                            height: 5px;
                            width: 12px;
                            border-bottom: 3px solid $gray-light;
                            content: '';
                        }
                    }

                    &:last-child > div.preop-dataset-eye-group-link::before {
                        display: none;
                    }
                }
            }

            &-item {
                font-size: 0.857rem;
                font-weight: 400;
                line-height: 1.14rem;

                &-title {
                    button {
                        font-weight: 600;
                        line-height: 1.5rem;

                        .preop-dataset-eye-group-item-title {
                            padding-left: 6px;

                            &-icon-incomplete {
                                opacity: 0.25;
                            }

                            &-incomplete {
                                font-weight: 400;
                            }
                        }
                    }
                }
                div.show-check::after {
                    position: relative;
                    top: 2px;
                    display: inline-flex;
                    content: '';
                    background-image: url('../../../assets/circleCheck.svg');
                    background-size: 13px 13px;
                    background-repeat: no-repeat;
                    height: 13px;
                    width: 13px;
                }

                &-detail {
                    padding-left: 32px;
                }

                > div.preop-dataset-eye-group-item-detail:last-child {
                    font-weight: 700;
                }
            }
        }
    }
}
</style>
